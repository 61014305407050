var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-space-y-2"},[_c('div',{staticClass:"tw-flex tw-space-x-3 tw-w-full tw-mb-3"},[_c('div',{staticClass:"tw-text-center tw-w-52 tw-h-32"},[_c('UploadImage',{ref:"uploadimage",model:{value:(_vm.model.photo),callback:function ($$v) {_vm.$set(_vm.model, "photo", $$v)},expression:"model.photo"}})],1),_c('div',{staticClass:"tw-w-full tw-space-y-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("shop.name")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.shop_name),expression:"model.shop_name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has('shop_name')
                                },attrs:{"type":"text","placeholder":_vm.$t('shop.name')},domProps:{"value":(_vm.model.shop_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "shop_name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('shop_name'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("shop_name"))+" ")]):_vm._e()]),(_vm.$whois.admin())?_c('div',{staticClass:"col-md-4 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("shop.agency")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.agencies,"showSearch":"","filterOption":function (input, option) { return option.componentOptions.children[0].text
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0; },"placeholder":_vm.$t('shop.agency')},model:{value:(_vm.model.refer_from_agency_id),callback:function ($$v) {_vm.$set(_vm.model, "refer_from_agency_id", $$v)},expression:"model.refer_from_agency_id"}}),(_vm.errors.has('refer_from_agency_id'))?_c('div',{staticClass:"tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("refer_from_agency_id"))+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col-md-4 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("shop.businessCategory")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.businessCategory,"placeholder":_vm.$t('shop.businessCategory')},model:{value:(_vm.model.business_category_id),callback:function ($$v) {_vm.$set(_vm.model, "business_category_id", $$v)},expression:"model.business_category_id"}}),(_vm.errors.has('business_category_id'))?_c('div',{staticClass:"tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("business_category_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("shop.shopType")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.shopeType,"placeholder":_vm.$t('shop.shopType')},model:{value:(_vm.model.shop_type_id),callback:function ($$v) {_vm.$set(_vm.model, "shop_type_id", $$v)},expression:"model.shop_type_id"}}),(_vm.errors.has('shop_type_id'))?_c('div',{staticClass:"tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("shop_type_id"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-4 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("shop.phoneNumber")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.phone_number),expression:"model.phone_number"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has('phone_number')
                                },attrs:{"type":"text","placeholder":_vm.$t('shop.phoneNumber')},domProps:{"value":(_vm.model.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "phone_number", $event.target.value)}}}),(_vm.errors.has('phone_number'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("phone_number"))+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-4 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("shop.allowService")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.serviceType,"mode":"multiple","placeholder":_vm.$t('shop.serviceType')},model:{value:(_vm.model.service_type_allow),callback:function ($$v) {_vm.$set(_vm.model, "service_type_allow", $$v)},expression:"model.service_type_allow"}}),(_vm.errors.has('service_type_allow'))?_c('div',{staticClass:"tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("service_type_allow"))+" ")]):_vm._e()],1)])])]),_c('div',{staticClass:"tw-p-3 tw-border-2 tw-rounded tw-space-y-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("shop.feePerPackage")))]),_c('ts-currency-input',{staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('fee_per_package')
                            },attrs:{"type":"number","step":"any","readonly":!_vm.model.is_stock_at_jd,"placeholder":_vm.$t('shop.feePerPackage')},model:{value:(_vm.model.fee_per_package),callback:function ($$v) {_vm.$set(_vm.model, "fee_per_package", _vm._n($$v))},expression:"model.fee_per_package"}}),(_vm.errors.has('fee_per_package'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("fee_per_package"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.sale")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.employee,"showSearch":"","filterOption":function (input, option) { return option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0; },"placeholder":_vm.$t('shop.agency')},model:{value:(_vm.model.saler_id),callback:function ($$v) {_vm.$set(_vm.model, "saler_id", $$v)},expression:"model.saler_id"}}),(_vm.errors.has('saler_id'))?_c('div',{staticClass:"tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("saler_id"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.email")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email),expression:"model.email"}],staticClass:"form-control",class:{ 'is-invalid': _vm.errors.has('email') },attrs:{"type":"text","placeholder":_vm.$t('shop.email')},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value)}}}),(_vm.errors.has('email'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("email"))+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.telegramChartId")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.tel_chat_id),expression:"model.tel_chat_id"}],staticClass:"form-control",class:{ 'is-invalid': _vm.errors.has('tel_chat_id') },attrs:{"type":"text","placeholder":"Ex: 969134869"},domProps:{"value":(_vm.model.tel_chat_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "tel_chat_id", $event.target.value)}}}),(_vm.errors.has('tel_chat_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("tel_chat_id"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.standardFeeUsd")))]),_c('ts-currency-input',{staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('standard_fee_usd')
                            },attrs:{"type":"number","step":"any","placeholder":_vm.$t('shop.standardFeeUsd')},model:{value:(_vm.model.standard_fee_usd),callback:function ($$v) {_vm.$set(_vm.model, "standard_fee_usd", _vm._n($$v))},expression:"model.standard_fee_usd"}}),(_vm.errors.has('standard_fee_usd'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("standard_fee_usd"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.expressFeeUsd")))]),_c('ts-currency-input',{staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('express_fee_usd')
                            },attrs:{"type":"number","step":"any","placeholder":_vm.$t('shop.expressFeeUsd')},model:{value:(_vm.model.express_fee_usd),callback:function ($$v) {_vm.$set(_vm.model, "express_fee_usd", _vm._n($$v))},expression:"model.express_fee_usd"}}),(_vm.errors.has('express_fee_usd'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("express_fee_usd"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.standardDriverFeeUsd")))]),_c('ts-currency-input',{staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'standard_driver_fee_usd'
                                )
                            },attrs:{"type":"number","step":"any","placeholder":_vm.$t('shop.standardDriverFeeUsd')},model:{value:(_vm.model.standard_driver_fee_usd),callback:function ($$v) {_vm.$set(_vm.model, "standard_driver_fee_usd", _vm._n($$v))},expression:"model.standard_driver_fee_usd"}}),(_vm.errors.has('standard_driver_fee_usd'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("standard_driver_fee_usd"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.expressDriverFeeUsd")))]),_c('ts-currency-input',{staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'express_driver_fee_usd'
                                )
                            },attrs:{"type":"number","step":"any","placeholder":_vm.$t('shop.expressDriverFeeUsd')},model:{value:(_vm.model.express_driver_fee_usd),callback:function ($$v) {_vm.$set(_vm.model, "express_driver_fee_usd", _vm._n($$v))},expression:"model.express_driver_fee_usd"}}),(_vm.errors.has('express_driver_fee_usd'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("express_driver_fee_usd"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("shop.servicePaidBy")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":[
                                { value: 2, label: 'Shop' },
                                { value: 1, label: 'Buyer' }
                            ]},model:{value:(_vm.model.service_paid_by),callback:function ($$v) {_vm.$set(_vm.model, "service_paid_by", $$v)},expression:"model.service_paid_by"}}),(_vm.errors.has('service_paid_by'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("service_paid_by"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ts-checkbox',{model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}},[_vm._v(_vm._s(_vm.$t("shop.active")))])],1),_c('div',{staticClass:"col-md-6"},[_c('ts-checkbox',{on:{"change":function () { return (_vm.model.fee_per_package = 0.25); }},model:{value:(_vm.model.is_stock_at_jd),callback:function ($$v) {_vm.$set(_vm.model, "is_stock_at_jd", $$v)},expression:"model.is_stock_at_jd"}},[_vm._v(_vm._s(_vm.$t("shop.stockAtJd")))])],1),_c('div',{staticClass:"col-md-6"},[_c('ts-checkbox',{model:{value:(_vm.model.acknowledge_tc),callback:function ($$v) {_vm.$set(_vm.model, "acknowledge_tc", $$v)},expression:"model.acknowledge_tc"}},[_vm._v(_vm._s(_vm.$t("shop.approved")))])],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }