<template>
    <div>
				<TsMapAutocomplete
          v-model="model.location_name"
          @setLocation="getCurrentGeoLocation"
        />
        <GmapMap
            :center="center"
            :zoom="zoom"
            :options="{
                streetViewControl: true
            }"
            map-type-id="roadmap"
            class="tw-w-full tw-h-44 tw-mt-2"
            @click="mapClick"
        >
            <GmapMarker
                :clickable="true"
                :position="marker"
            />
        </GmapMap>
    </div>
</template>

<script>
export default {
    name: "geoLocationShop",
		props:['value'],
    data() {
        return {
						model: {},
            zoom: 7,
            center: { lat: 12.565679, lng: 104.990967 },
            marker: { lat: undefined, lng: undefined }
        };
    },
		created() {
			this.model = this.value
		},
		methods: {
        mapClick(value) {
						this.marker.lat = value.latLng.lat();
            this.marker.lng = value.latLng.lng();
						this.model.geo_location.lat = value.latLng.lat();
						this.model.geo_location.lng = value.latLng.lng();


						console.log(value.latLng.lat(), value.latLng.lng())
        },
        getCurrentGeoLocation(value) {
						// navigator.geolocation.getCurrentPosition(position => {
            //     this.center = {
            //         lat: position.coords.latitude,
            //         lng: position.coords.longitude
            //     };
            //     this.marker = this.center;
						// 		this.model.geo_location.lat = position.coords.latitude
						// 		this.model.geo_location.lng = position.coords.longitude
						// 		this.zoom = 18
            // });

						this.center = {
								lat: value.lat,
								lng: value.lng
						};
						this.marker = this.center;
						this.model.geo_location.lat = value.lat
						this.model.geo_location.lng = value.lng
						this.zoom = 18
        }
    }
};
</script>
