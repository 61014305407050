<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("shop.province") }}</label>
                <a-select
                    v-model="model.province_code"
                    :options="provinceList"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                    @change="onProvinceChange"
                    class="tw-w-full"
                    :placeholder="$t('shop.province')"
                ></a-select>
            </div>
        </div>
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("shop.district") }}</label>
                <a-select
                    v-model="model.district_code"
                    :options="districtList"
                    @change="onDistrictChange"
                    class="tw-w-full"
                    :placeholder="$t('shop.district')"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                ></a-select>
            </div>
        </div>
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("shop.commune") }}</label>
                <a-select
                    v-model="model.commune_code"
                    :options="communeList"
                    @change="onCommuneChange"
										:placeholder="$t('shop.commune')"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                    class="tw-w-full"
                ></a-select>
            </div>
        </div>
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("shop.village") }}</label>
                <a-select
                    v-model="model.village_code"
                    :options="villageList"
										:placeholder="$t('shop.village')"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                    class="tw-w-full"
                ></a-select>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
export default {
    name: "addressAgency",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("agency/shop", ["edit_data", 'formModels']),
        provinceList() {
            if (!this.formModels.provinces) return [];

            return this.formModels.provinces.map(el => ({
                value: el.province_code,
                label: el.name_en
            }));
        },
        districtList() {
            if (!this.formModels.districts) return [];

            return this.formModels.districts.map(el => ({
                value: el.district_code,
                label: el.name_en
            }));
        },
        communeList() {
            if (!this.formModels.communes) return [];

            return this.formModels.communes.map(el => ({
                value: el.commune_code,
                label: el.name_en
            }));
        },
        villageList() {
            if (!this.formModels.villages) return [];

            return this.formModels.villages.map(el => ({
                value: el.village_code,
                label: el.name_en
            }));
        }
    },
    methods: {
        ...mapActions("agency/shop", ["getFormViewData"]),
        fetchFormView(control, params) {
            this.$emit("loading", true);
            this.getFormViewData({ params: params, control: control})
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.$emit("loading", false);
                });
        },
        onProvinceChange(value) {
            this.model.district_code = "";
            this.model.commune_code = "";
            this.model.village_code = "";
            this.fetchFormView("districts", {
                params: {
                    provinceCode: value,
                    districtCode: "",
                    communeCode: "",
										fnName: 'districts'
                }
            });
        },
        onDistrictChange(value) {
            this.model.commune_code = "";
            this.model.village_code = "";
            this.fetchFormView("communes", {
                params: {
                    provinceCode: "",
                    districtCode: value,
                    communeCode: "",
										fnName: 'communes'
                }
            });
        },
        onCommuneChange(value) {
            this.model.village_code = "";
            this.fetchFormView("villages", {
                params: {
                    provinceCode: "",
                    districtCode: "",
                    communeCode: value,
										fnName: 'villages'
                }
            });
        },
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
