<template>
    <ts-panel>
        <ts-panel-wrapper>
            <ts-divider-title>{{ $t("shop.exchangeRate") }}</ts-divider-title>
            <table class="tw-w-full">
                <thead class="tw-border-b tw-border-gray-500 tw-bg-gray-100">
                    <tr>
                        <th class="tw-py-2 tw-px-1 tw-w-10 tw-text-center">
                            {{ $t("shop.no") }}
                        </th>
                        <th class="tw-py-2 tw-px-1">
                            {{ $t("shop.fromCurrency") }}
                        </th>
                        <th class="tw-py-2 tw-px-1">
                            {{ $t("shop.toCurrency") }}
                        </th>
                        <th class="tw-py-2 tw-px-1 tw-w-32">
                            {{ $t("shop.fxRate") }}
                        </th>
                        <th class="tw-w-8 tw-py-2 tw-px-1">
                            <a
																@click.prevent="addRow"
                                href="#"
                                class="tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-full tw-text-white"
                                ><i class="fa fa-plus"></i
                            ></a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(ex, index) in model.exchange_rate"
                        :key="index"
                        class="tw-border-b"
                    >
                        <td class="tw-px-2">{{ index + 1 }}</td>
                        <td class="tw-px-2">{{ defaultCurrency.currency_code }}</td>
                        <td class="tw-px-2">
													<select
														v-model="ex.currency_id_to"
														class="tw-w-full tw-outline-none"
													>
														<option value="">{{ $t('shop.toCurrency') }}</option>
														<option
															v-for="(c, index) in currencies(ex.currency_id_to)"
															:key="index"
															:value="c.currency_id"
														>{{ c.currency_code }}</option>
													</select>
												</td>
                        <td class="tw-px-2">
                            <input
                                class="tw-outline-none tw-rounded tw-border-l tw-border-r tw-py-1 tw-px-2 tw-w-32 tw-float-right"
                                type="number"
																v-model.number="ex.fx_rate"
                                :placeholder="$t('shop.fxRate')"
                            />
                        </td>
                        <td class="tw-text-center">
                            <a href="#" class="tw-text-red-600"
															@click.prevent="remove(index)"
														>
                                <i class="fa fa-trash"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { mapState } from "vuex";
import { includes } from 'lodash'

export default {
    name: "shopExchangeRate",
		props:['value'],
		data() {
			return {
				model: {}
			}
		},
		created() {
			this.model = this.value
		},
    computed: {
        ...mapState("agency/shop", ["formModels"]),
        currencies: vm => currentRow =>  {
            if (!vm.formModels.currency) return [];

						if(currentRow != undefined)
						{
							return vm.formModels.currency.filter(el => !el.is_default)
								.filter(
									c => !includes(vm.model.exchange_rate.filter(ex => ex.currency_id_to != currentRow).map(el => el.currency_id_to), c.currency_id)
								);
						}

						return vm.formModels.currency.filter(el => !el.is_default)
        },
        defaultCurrency() {
            if (!this.formModels.currency) return "";
            return this.formModels.currency.find(el => el.is_default)
        }
    },
		methods: {
			addRow() {
				this.model.exchange_rate.push({
					currency_id_from: this.defaultCurrency.currency_id,
					currency_id_to: '',
					fx_rate: ''
				})
			},
			remove(index){
				this.model.exchange_rate.splice(index, 1)
			}
		},
};
</script>
